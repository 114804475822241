@font-face {
  font-family: "Almarai";
  src: url("/assets/fonts/Almarai/Almarai-Regular.woff") format("woff"),
    /* Modern Browsers */
    url("/assets/fonts/Almarai/Almarai-Regular.ttf") format("truetype");
  /* Legacy Browsers */
}

:root {
  --bs-font-sans-serif: "Almarai", sans-serif, system-ui, -apple-system,
    "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

@import url('./primeng.custom.scss');

input[inputMode="decimal"],
input[inputMode="currency"] {
  text-align: left;
}

html[dir="rtl"] {
  .p-dropdown .p-dropdown-clear-icon {
    left: 2.375rem;
    right: unset;
  }

  .p-input-icon-right>.p-icon-wrapper,
  .p-input-icon-right>i:last-of-type {
    left: 0.75rem;
    right: unset;
  }

  .pe-6 {
    padding-left: 5rem !important;
  }

  .pe-7 {
    padding-left: 6rem !important;
  }

  .pe-8 {
    padding-left: 7rem !important;
  }

  .pe-9 {
    padding-left: 9rem !important;
  }

  .pe-12 {
    padding-left: 12rem !important;
  }
}

html[dir="ltr"] {
  .p-dropdown .p-dropdown-clear-icon {
    right: 2.375rem;
    left: unset;
  }

  .p-input-icon-right>.p-icon-wrapper,
  .p-input-icon-right>i:last-of-type {
    right: 0.75rem;
    left: unset;
  }

  .pe-6 {
    padding-right: 5rem !important;
  }

  .pe-7 {
    padding-right: 6rem !important;
  }

  .pe-8 {
    padding-right: 7rem !important;
  }

  .pe-9 {
    padding-right: 9rem !important;
  }

  .pe-12 {
    padding-right: 12rem !important;
  }
}

span.pipe {
  margin: 0 1rem;
}

span.pipe::after {
  content: "|";
  text-transform: none;
}

label.required::after {
  content: "*";
  color: red;
}

.p-multiselect-items-wrapper {
  padding: 0 1rem;
}

.p-multiselect-items {
  margin: 0;
  padding: 0.25rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.75rem 1.2rem;
}

.p-checkbox {
  margin: 0 0.5rem;
}

.p-dropdown,
.p-inputwrapper:not([styleclass="p-paginator-page-options"]),
.p-inputtext,
.p-inputnumber {
  width: 100% !important;
  // max-width: 50vw !important;
}

.p-paginator-bottom {

  button {
    min-width: 2rem !important;
  }

  span>button {
    min-width: 2.5rem !important;
  }
}



.p-dropdown.p-paginator-page-options {
  width: unset !important;
}

.p-dropdown-items-wrapper>ol,
.p-dropdown-items-wrapper>ul {
  padding-right: 0.1rem;
}

.p-inputtext {
  padding: 0.375rem 0.75rem;
}

html[dir="rtl"] {
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    left: 0.75rem !important;
    right: unset;
    color: var(--primary-color);
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-icon {
    left: 0.75rem !important;
    right: unset;
    color: var(--primary-color);
  }

  .input-group-text-no-splitter {
    border-right: unset !important;
  }

  .badge-label {
    border-radius: 0px 8px 8px 0px;
  }

  .badge-value {
    border-radius: 8px 0px 0px 8px;
  }

  .ql-editor {
    direction: rtl;
    text-align: right;
  }

  /* editor */
  .ql-snow .ql-picker-label {
    padding-left: 8px !important;
    padding-right: 14px !important;
  }

  .ql-editor>ol>li {
    padding: 0 1rem 0;
  }

  .ql-editor li>.ql-ui:before {
    margin-right: -0.8em;

  }



  .was-validated .form-control:invalid .submitted,
  p-dropdown.submitted.ng-invalid>div,
  p-inputnumber.submitted.ng-invalid>span>input,
  .form-control.is-invalid.submitted {
    border-color: red;
  }

  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: unset !important;
  }


  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 0.75rem;
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  svg.icon-primary {
    margin-left: 0.375rem;
  }
}

html[dir="ltr"] {
  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
    color: var(--primary-color);
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-icon {
    right: 0.75rem;
    color: var(--primary-color);
  }



  .input-group-text-no-splitter {
    border-right: unset !important;
  }

  .badge-label {
    border-radius: 8px 0px 0px 8px;
  }

  .badge-value {
    border-radius: 0px 8px 8px 0px;
  }

  svg.icon-primary {
    margin-right: 0.375rem;
  }
}

.btn-action {
  min-width: 5rem;
}

.btn-action-md {
  min-width: 10rem;
}

.btn-action-lg {
  min-width: 15rem;
}

quill-editor {
  width: 100%;
}

.p-datatable-wrapper {
  overflow: visible;
}

.p-multiselect-items-wrapper {
  padding: unset !important;
}

svg.icon-primary {
  height: 24px;
  width: 24px;

  path {
    fill: var(--primary-color);
  }
}

.strok-primary {
  stroke: var(--primary-color);
}


.nav-left::before {
  transform: rotate(45deg) !important;
}

div[aria-roledescription='Slide'],
.p-galleria-thumbnail-wrapper {
  background-color: black !important;
}

.p-password-panel,
.password-restriction {
  width: 200px;

  li {
    font-size: 0.85rem;
  }

  li>i {
    padding-top: 0.2rem;
  }

}

.p-password-info {
  padding: 0.375rem 0;
}

.clickable {
  cursor: pointer;
}

.p-colorpicker-preview {
  padding: 0.375rem 0.75rem;
}

.no-result-icon {
  height: 200px;
  width: auto;
  fill: var(--primary-color);
}

.p-fileupload-content {
  padding: unset !important;
  background: unset !important;
  border: unset !important;

  .list-group>.list-group-item {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid var(--bs-gray-400) !important;
    border-radius: unset !important;
  }

}

.p-fileupload-buttonbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  border: unset !important;

}

.p-multiselect .p-multiselect-label {
  padding: 6px 12px !important;
}

