.quill-viewer {
    overflow-wrap: break-word;

    .indent-4 {
        margin-right: 10.5rem;
    }
}

// Define base indentation variables
$redulce-percenteg: 0.35;
$indent-8: 25.5em * $redulce-percenteg; // 30% reduction
$indent-7: 22.5em * $redulce-percenteg;
$indent-6: 19.5em * $redulce-percenteg;
$indent-5: 16.5em * $redulce-percenteg;
$indent-4: 13.5em * $redulce-percenteg;
$indent-3: 10.5em * $redulce-percenteg;
$indent-2: 7.5em * $redulce-percenteg;
$indent-1: 4.5em * $redulce-percenteg;


html[dir='rtl'] {

    // Styles for .ql-editor
    .ql-editor {
        li.ql-indent-8:not(.ql-direction-rtl) {
            padding-right: $indent-8;
        }

        li.ql-indent-7:not(.ql-direction-rtl) {
            padding-right: $indent-7;
        }

        li.ql-indent-6:not(.ql-direction-rtl) {
            padding-right: $indent-6;
        }

        li.ql-indent-5:not(.ql-direction-rtl) {
            padding-right: $indent-5;
        }

        li.ql-indent-4:not(.ql-direction-rtl) {
            padding-right: $indent-4;
        }

        li.ql-indent-3:not(.ql-direction-rtl) {
            padding-right: $indent-3;
        }

        li.ql-indent-2:not(.ql-direction-rtl) {
            padding-right: $indent-2;
        }

        li.ql-indent-1:not(.ql-direction-rtl) {
            padding-right: $indent-1;
        }
    }

    // Styles for .quill-viewer
    .quill-viewer {
        .indent-8 {
            margin-right: $indent-8;
        }

        .indent-7 {
            margin-right: $indent-7;
        }

        .indent-6 {
            margin-right: $indent-6;
        }

        .indent-5 {
            margin-right: $indent-5;
        }

        .indent-4 {
            margin-right: $indent-4;
        }

        .indent-3 {
            margin-right: $indent-3;
        }

        .indent-2 {
            margin-right: $indent-2;
        }
    }
}

html[dir='ltr'] {

    // Styles for .ql-editor
    .ql-editor {
        li.ql-indent-8:not(.ql-direction-ltr) {
            padding-left: $indent-8;
        }

        li.ql-indent-7:not(.ql-direction-ltr) {
            padding-left: $indent-7;
        }

        li.ql-indent-6:not(.ql-direction-ltr) {
            padding-left: $indent-6;
        }

        li.ql-indent-5:not(.ql-direction-ltr) {
            padding-left: $indent-5;
        }

        li.ql-indent-4:not(.ql-direction-ltr) {
            padding-left: $indent-4;
        }

        li.ql-indent-3:not(.ql-direction-ltr) {
            padding-left: $indent-3;
        }

        li.ql-indent-2:not(.ql-direction-ltr) {
            padding-left: $indent-2;
        }

        li.ql-indent-1:not(.ql-direction-ltr) {
            padding-left: $indent-1;
        }
    }

    // Styles for .quill-viewer
    .quill-viewer {
        .indent-8 {
            margin-left: $indent-8;
        }

        .indent-7 {
            margin-left: $indent-7;
        }

        .indent-6 {
            margin-left: $indent-6;
        }

        .indent-5 {
            margin-left: $indent-5;
        }

        .indent-4 {
            margin-left: $indent-4;
        }

        .indent-3 {
            margin-left: $indent-3;
        }

        .indent-2 {
            margin-left: $indent-2;
        }
    }
}