/* You can add global styles to this file, and also import other style files */
@import url("../../../node_modules/bootstrap/scss/bootstrap.scss");
@import url('../../shared/src/assets/shared.scss');


:root {
  --bs-primary-rgb: 102, 103, 171;
  --bs-primary-rgba: rgba(var(--bs-primary-rgb), 0.25);
  --primary-color: #6667ab;
  --bs-primary: var(--primary-color);
  /* Use variable to avoid redundancy */
  --bs-border-color: #c1c1c1;
}

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow-x: hidden;

}

html[dir="rtl"] {

  .text-start {
    text-align: right !important;
  }

  .text-end {
    text-align: left;
  }
}

.p-dropdown-items-wrapper>ol,
.p-dropdown-items-wrapper>ul {
  padding-right: 0.1rem;
}

.p-datatable .p-datatable-tbody>tr>td {
  text-align: unset !important;
  transition: all 300ms;
}

.p-dropdown,
.p-multiselect,
.p-inputtext {
  width: 100%;
  border-color: white !important;
  padding: 5px;
  background-color: #F4F4F5;
}

.p-multiselect-items-wrapper>ol,
.p-multiselect-items-wrapper>ul {
  padding-right: 0.1rem;
}

.p-multiselect {
  width: 100%;
  border-color: white !important;
}


.btn {
  border-radius: unset !important;
}

html[dir="rtl"] {

  .absolute-end {
    left: 1rem;
  }

  .absolute-start {
    right: 1rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    left: 0.75rem !important;
    right: unset;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-icon {
    left: 0.75rem !important;
    right: unset;
  }




}


html[dir="ltr"] {

  .absolute-end {
    right: 1rem;
  }

  .absolute-start {
    left: 1rem;
  }

  .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: 0.75rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-icon {
    right: 0.75rem;
  }
}

.customInputNumber {
  border: var(--bs-border-width) solid var(--bs-border-color);
}

input:focus {
  border-color: var(--primary-color) !important;
}

.form-control {
  padding-top: 10px !important;
  padding-bottom: 9px;
  border-radius: 3px;
  background: #F4F4F5;
  border: none;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem var(--bs-primary-rgba);
  background: #F4F4F5;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--primary-color);
  --bs-btn-hover-border-color: var(--primary-color);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--primary-color);
  --bs-btn-active-border-color: var(--primary-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--primary-color);
  --bs-btn-disabled-border-color: var(--primary-color);
}


.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-checkbox .p-checkbox-box.p-highlight,
.p-slider:not(.p-disabled):hover .p-slider-range {
  border-color: var(--primary-color);
  background: var(--primary-color);
}

.p-slider:not(.p-disabled):hover .p-slider-handle {
  border-color: var(--primary-color);
}

.p-checkbox .p-checkbox-box:not(.p-disabled).p-focus,
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus,
.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus,
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px var(--primary-color);
}

input[type="tel"] {
  padding-left: 85px !important;
}

.p-dropdown-items-wrapper>ol,
.p-dropdown-items-wrapper>ul {
  padding-left: unset !important;
}